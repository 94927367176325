import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
const Logout = () => {
    const history = useHistory();
    useEffect(() => {
        //console.log('logout');
        localStorage.clear();
        history.push('/');
    });
    return (

        <Container fluid >

        </Container>

    );
}

export default Logout;