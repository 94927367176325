import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, Form, Button, Select } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Topbar from "../components/Topbar";
import PublishedItems from "../components/PublishedItems";
import Footer from "../components/Footer";
import setting from '../setting.json';
const PublishedNews = () => {

    // const [category, setCategory] = useState(null);
    const [list, setList] = useState('');
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [category, setCategory] = useState('');


    const getCategories = async () => {
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        //console.log(user);
        await fetch(url + '/api/getAllCategoriesByUserId/' + user)
            .then(response => response.json())
            .then(json => {
                setList(json);

            }
            );
    }
    const updateStartDate = () => {
        var startd = document.getElementById('startd').value;
        //console.log(startd);
        /* var day = startd.getDate();
        var month = startd.getMonth();
        var year = startd.getFullYear();
        var dstr = `${year}-${month}-${day}`;
        console.log(dstr) */
        setStartDate(startd);

    }
    const updateEndDate = () => {
        var endd = document.getElementById('endd').value;
        //console.log(endd);
        setEndDate(endd);
    }
    const updateCategory = () => {
        var cat = document.getElementById('cat').value;
        //console.log(endd);
        setCategory(cat);
    }
    const searchText = () => {
        //alert('test');
        var text = document.getElementById('search').value;
        //console.log(cat);
        setSearch(text);
    }
    useEffect(() => {
        getCategories();
    }, []);
    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h2 className="pagetitle">Published News </h2>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label> From Date</Form.Label>
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            selected={startDate}
                                            onChange={updateStartDate}
                                            id="startd"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3">

                                        <Form.Label> To Date</Form.Label>
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            selected={endDate}
                                            onChange={updateEndDate}
                                            id="endd"
                                        />


                                    </Form.Group>
                                </Col>
                                {/* <Col md={2}>
                                    <Form.Group className="mb-3">

                                        <Form.Label> Category</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            id="cat"
                                            onChange={updateCategory}
                                        >
                                            <option>Select Category</option>
                                            {list && list.map((r) => (

                                                <option value={r.id}>{r.name}</option>
                                            ))}



                                        </Form.Select>


                                    </Form.Group>
                                </Col> */}
                                <Col md={8}>
                                    <Form.Group className="mb-6" >

                                        <Form.Label> Search Text</Form.Label>

                                        <Form.Control
                                            size="md"
                                            type="text"

                                            onChange={searchText}
                                            id="search"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>

                                </Col>


                                {/* <Col>

                                    <Form.Group className="mb-3">
                                        <Button >Search</Button>
                                    </Form.Group>
                                </Col> */}

                            </Row>

                            <PublishedItems startDate={startDate} endDate={endDate} search={search} category={category} />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Footer />
        </Container >
    );
}
export default PublishedNews;