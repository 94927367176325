import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from "react";

import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import GroupIcon from '@mui/icons-material/Group';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Widget from '../../components/Widget';

import setting from '../../setting.json';
const Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalNews, setTotalNews] = useState(0);
    const [draftNews, setDraftNews] = useState(0);
    const [totalCategory, setTotalCategory] = useState(0);
    const [deletedNews, setDeletedNews] = useState(0);

    const getDashboardData = async () => {
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        var role = JSON.parse(localStorage.getItem('user')).role;
        //console.log(user);
        await fetch(url + '/api/getDashboardData/' + user + '/' + role)
            .then(response => response.json())
            .then(json => {
                setTotalNews(json.totalNews);
                setDraftNews(json.draftNews);
                setTotalCategory(json.totalCategory);
                setDeletedNews(json.deletedNews);
            }
            );
    }
    useEffect(() => {
        getDashboardData();
    }, []);
    return (<Row>
        <Col xs={12} md={3} className="widget">
            <Widget
                icon={<AddCardIcon fontSize="large" />}
                bg="warning"
                title="Total News"
                value={totalNews}
                linktext="View"
                link="/addNews" />
        </Col>
        <Col xs={12} md={3} className="widget">
            <Widget
                icon={<DraftsIcon fontSize="large" />}
                bg="success"
                title="Draft News"
                value={draftNews}
                linktext="View"
                link="/draftNews" />
        </Col>
        <Col xs={12} md={3} className="widget">
            <Widget
                icon={<DashboardIcon fontSize="large" />}
                bg="secondary"
                title="Category"
                value={totalCategory}
                linktext="View"
                link="#" />
        </Col>
        <Col xs={12} md={3} className="widget">
            <Widget
                icon={<DeleteSweepIcon fontSize="large" />}
                bg="danger"
                title="Deleted News"
                value={deletedNews}
                linktext="View"
                link="/deletedNews" />
        </Col>


    </Row>);
}

export default Dashboard;