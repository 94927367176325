import { Row, Col, Form } from 'react-bootstrap';

const CategoryTree = ({ list, selectCategory, newscategory }) => {

    return (
        <Row>
            <Col md={12} className="mb-3">
                {newscategory &&
                    list.map((r) =>
                        <Form.Group key={r.id} controlId={r.id} >
                            {<Form.Check
                                type='checkbox'
                                className={r.level}
                                value={r.id}
                                label={r.name}
                                defaultChecked={newscategory.indexOf(r.id) !== -1 ? true : false}
                                onChange={selectCategory}
                            />}
                        </Form.Group>
                    )
                }
            </Col>
        </Row>

    );
}
export default CategoryTree;