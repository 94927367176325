import { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, ListGroup, Figure, Form, InputGroup, Button, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import setting from '../setting.json';
const Profile = () => {
    const [userData, setUserData] = useState(null);
    const history = useHistory();

    const getUserProfile = async () => {
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        //console.log(user);
        await fetch(url + '/api/getUserProfileById/' + user)
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                setUserData(json);
                document.getElementById('fbid').value = json.fbid;
                document.getElementById('instaid').value = json.instaid;
                document.getElementById('ytid').value = json.ytid;
                document.getElementById('twid').value = json.twid;
                document.getElementById('about').value = json.about;

            }
            );
    }

    useEffect(() => {
        getUserProfile();
        //console.log(userData);
    }, []);


    const updateUserProfile = async (event) => {
        event.preventDefault();
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        const formData = new FormData();
        formData.append("fbid", document.getElementById('fbid').value);
        formData.append("instaid", document.getElementById('instaid').value);
        formData.append("ytid", document.getElementById('ytid').value);
        formData.append("twid", document.getElementById('twid').value);
        formData.append("about", document.getElementById('about').value);
        formData.append("userid", user);
        console.log(formData);
        await fetch(url + '/api/updateUserProfile/', {
            "method": "POST",
            "body": formData
        })
            .then(response => response.json())
            .then(json => {


            }
            );

    }
    const changePassword = async (event) => {
        event.preventDefault();
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        var oldpass = document.getElementById('oldpass').value;
        var newpass = document.getElementById('newpass').value;
        if (oldpass !== '' && newpass !== '') {
            const formData = new FormData();
            formData.append("oldpass", oldpass);
            formData.append("newpass", newpass);
            /*  formData.append("confirmpass", document.getElementById('confirmpass').value); */
            formData.append("userid", user);
            console.log(formData);
            await fetch(url + '/api/changePassword/', {
                "method": "POST",
                "body": formData
            })
                .then(response => response.json())
                .then(json => {
                    if (json === 1) {
                        document.getElementById('passmsg').innerHTML = "Password Successfully Changed";
                    } else {
                        document.getElementById('passmsg').innerHTML = "Wrong Password Entered";
                    }

                }
                );
        } else {
            document.getElementById('passmsg').innerHTML = "Password can not be blank";
        }


    }


    return (


        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} xs={12} className="rightsect">
                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Profile Page</h3>
                    <Row>
                        <Col md={4} >
                            <Card >
                                <Card.Body>
                                    <Card.Title>User Profile</Card.Title>
                                    {userData && <Figure id="profileimage">
                                        <Figure.Image
                                            width={171}
                                            height={180}
                                            alt="171x180"
                                            src={`${setting.url}/wp-content/uploads/user/${userData.image}`}

                                        />

                                    </Figure>}
                                    {userData && <ListGroup>
                                        <ListGroup.Item><b>Role:</b> {userData.role}</ListGroup.Item>
                                        <ListGroup.Item><b>Name:</b> {userData.fname} {userData.lname}</ListGroup.Item>
                                        <ListGroup.Item><b>Email:</b> {userData.email}</ListGroup.Item>
                                        <ListGroup.Item><b>Phone:</b> {userData.phone}</ListGroup.Item>
                                    </ListGroup>}
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col md={8} >
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="social"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >

                                        <Tab eventKey="social" title="Social Info">
                                            <Card.Title>User Profile</Card.Title>
                                            <InputGroup className="mb-3" >
                                                <InputGroup.Text id="basic-addon1"><FacebookIcon /></InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Facebook URL"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    id="fbid"
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3" >
                                                <InputGroup.Text id="basic-addon1"><InstagramIcon /></InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Instagram URL"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    id="instaid"
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1"><YouTubeIcon /></InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Youtube URL"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    id="ytid"
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1"><TwitterIcon /></InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Twitter URL"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    id="twid"
                                                />
                                            </InputGroup>
                                            <Form.Group className="mb-3" controlId="about">
                                                <Form.Label>About</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                >
                                                </Form.Control>

                                            </Form.Group>
                                            <Button variant="primary" id="save" onClick={updateUserProfile} >Update </Button>
                                        </Tab>
                                        <Tab eventKey="password" title="Password">
                                            <p id="passmsg"></p>
                                            <Form.Group className="mb-3" controlId="oldpass">
                                                <Form.Label>Old Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter Old Password" required />
                                            </Form.Group>


                                            <Form.Group className="mb-3" controlId="newpass" >
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control type="password" placeholder="Enter New Password" required />
                                            </Form.Group>
                                            {/* <Form.Group className="mb-3" controlId="confirmpass">
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control type="password" placeholder="Confirm New Password" required />
                                            </Form.Group> */}
                                            <Form.Group className="mb-3">
                                                <Button variant="primary" id="save" onClick={changePassword} >Submit </Button>
                                            </Form.Group>

                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>


                        </Col>
                    </Row>

                </Col>
            </Row>


            <Footer />

        </Container>

    );
}

export default Profile;