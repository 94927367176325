
import Sidebar from "../components/Sidebar";
import { Container, Row, Col } from 'react-bootstrap';
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import Dashboard1 from './admin/Dashboard';
import Dashboard2 from './editor/Dashboard';
import Dashboard3 from './correspondent/Dashboard';
const Home = () => {

    return (

        <Container fluid >
            <Row>

                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h2 className="pagetitle">Dashboard</h2>
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'admin' && <Dashboard1 />
                    }
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'editor' && <Dashboard2 />
                    }
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'correspondent' && <Dashboard3 />
                    }

                </Col>
            </Row>

            <Footer />
        </Container>

    );
}

export default Home;