import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import setting from '../setting.json';


const TopNews = ({ startDate, endDate }) => {
    const [list, setList] = useState(null);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('user')).id);



    const getNews = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        formData.append("start", startDate);
        formData.append("end", endDate);
        formData.append("userid", userId);
        await fetch(
            url + '/api/getTopNews/',
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                setList(json);

            }
            );
    }


    useEffect(() => {
        //console.log('useeffect run');
        getNews();

    }, []);


    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Category</th>

                        <th>Date</th>

                    </tr>
                </thead>
                <tbody>
                    {!list &&
                        <tr>
                            <td id="norecord">No Record found</td>
                        </tr>
                    }

                    {list && list.map((r) => (
                        <tr key={r.id}>
                            <td>{r.id}</td>
                            <td><a href={`${setting.url}/${r.slug}`} target="_blank" rel="noreferrer">{r.title}</a></td>
                            <td>{r.cats}</td>

                            <td>{r.pubDate}</td>

                        </tr>
                    ))}


                </tbody>
            </Table>

        </div>
    );
}

export default TopNews;