import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import setting from '../setting.json';


const DeletedItems = ({ startDate, endDate }) => {
    const [list, setList] = useState('');
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('user')).id);

    useEffect(() => {
        //console.log('useeffect run');
        getNews();
        getDeletedNewsCount();

    }, [newsCount]);
    const getNews = async () => {
        //console.log(startDate);
        var url = setting.api;
        const formData = new FormData();
        formData.append("page", currentPage);
        formData.append("start", startDate);
        formData.append("end", endDate);
        formData.append("userid", userId);
        await fetch(url + '/api/getDeletedNews/',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                setList(json);

            }
            );
    }
    const getDeletedNewsCount = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("start", startDate);
        formData.append("end", endDate);
        formData.append("userid", userId);
        await fetch(url + '/api/getDeletedNewsCount/',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                setNewsCount(json);
                setPageCount(Math.ceil(json / 10));
            }
            );
    }
    const restoreNews = async (event) => {

        var id = event.target.getAttribute('data');
        //console.log(id);
        var url = setting.api;
        await fetch(url + '/api/restoreNews/' + id)
            .then(response => response.json())
            .then(json => {
                //console.log(json)
                if (json === 1) {
                    setNewsCount(newsCount - 1);
                }

            }
            );
    }
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }


    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }
    useEffect(() => {
        //console.log('useeffect run');
        getNews();

    }, [currentPage]);
    useEffect(() => {
        getNews();
        getDeletedNewsCount();

    }, [startDate, endDate]);



    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Author</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!list &&
                        <tr>
                            <td id="norecord">No Record found</td>
                        </tr>
                    }

                    {list && list.map((r) => (
                        <tr key={r.id}>
                            <td>{r.id}</td>
                            <td><a href={`${setting.url}/${r.slug}`} target="_blank" rel="noreferrer">{r.title}</a></td>
                            <td>{r.cats}</td>
                            <td>{r.user}</td>
                            <td>{r.pubDate}</td>
                            <td>

                                <Button className="delete" variant="warning" data={r.id} onClick={restoreNews}>Restore</Button>
                            </td>
                        </tr>
                    ))}


                </tbody>
            </Table>
            {
                newsCount &&
                <Row>
                    <Col></Col>
                    {
                        currentPage !== 1 ?
                            <Col >
                                <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                <Button className="center" onClick={clickPrev}>Prev</Button>
                            </Col> : <Col></Col>
                    }

                    <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                    {
                        currentPage !== pageCount ?
                            <Col >

                                <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                <Button className="center" onClick={clickLast}>Last</Button>
                            </Col> : <Col></Col>
                    }
                    <Col></Col>
                </Row>
            }
        </div>
    );
}

export default DeletedItems;